<template>
    <v-container fluid class="mb-12">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-col cols="12" class="mb-12">
            <v-tabs
                v-model="tab"
                color="#1565c0" 
                height="40"
            >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    Transfer Warehouse
                </v-tab>

                <v-tab href="#tab-2">
                    WH DESTINATION TEMPORARY
                </v-tab>

                <v-tab href="#tab-3">
                    WH DESTINATION
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    value="tab-1"
                >
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="deep-purple accent-4"
                                    ></v-progress-linear>
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="5" md="3">
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="mill"
                                                    :items="mills"
                                                    item-value="mill_id"
                                                    item-text="mill_name"
                                                    label="MILL ID"
                                                    @change="(event) => updateWarehouse(event)"
                                                    :disabled="disabled_mill == true"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="warehouse"
                                                    :items="warehouses"
                                                    item-value="wh_id"
                                                    item-text="descr"
                                                    label="ORI WH ID"
                                                    @change="updateWarehouseDest"
                                                    :disabled="disabled_wh == true"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="bin_wh"
                                                    :items="bin_whs"
                                                    item-value="bin_id"
                                                    item-text="descr"
                                                    label="ORI BIN ID"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="3">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="warehouse_dest"
                                                    :items="warehouses_dest"
                                                    item-value="wh_id"
                                                    item-text="descr"
                                                    label="DESTINATION WH ID"
                                                    @change="updateBinDest"
                                                    :disabled="disabled_wh_dest == true"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="bin_wh_dest"
                                                    :items="bin_wh_dests"
                                                    item-value="bin_id"
                                                    item-text="descr"
                                                    label="Dest BIN ID"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12" xs="12" sm="5" md="2"
                                            >
                                                <v-datetime-picker 
                                                    v-model="datetime" 
                                                    label="Date Trans"
                                                    :date-picker-props="dateProps"
                                                    time-format="HH:mm:ss"
                                                    dense
                                                    solo
                                                    :disabled="disabled_search == true"
                                                    class="border-12 v-input border-12 v-input--is-disabled v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-select v-autocomplete"
                                                ></v-datetime-picker>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="5" md="2"
                                            >
                                                <v-autocomplete
                                                    clearable
                                                    v-model="shift"
                                                    :items="shifts"
                                                    label="Shift"
                                                    dense
                                                    solo
                                                    :disabled="disabled_search == true"
                                                    class="border-12"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="5" md="2"
                                            >
                                                <v-text-field
                                                v-model="tr_id"
                                                label="TR ID"
                                                disabled
                                                dense
                                                solo
                                                class="border-12"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12" xs="12" sm="5" md="2"
                                            >
                                                <v-text-field
                                                v-model="periode"
                                                dense
                                                solo
                                                label="Period"
                                                disabled
                                                class="border-12"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field class="border-12" dense solo v-model="remark" label="Remark" :disabled="disabled_search == true"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-5"></v-divider>
                                        <v-row class="mt-5">
                                            <v-col cols="12" xs="12" sm="5" md="3">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="machine"
                                                    :items="machines"
                                                    item-value="mach_type"
                                                    item-text="descr"
                                                    label="Machine"
                                                    class="border-12"
                                                    hide-details
                                                    @change="(event) => updateGroupJenis(event)"
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="3">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="group"
                                                    :items="groups"
                                                    item-value="jenis"
                                                    item-text="jenis"
                                                    label="Group ID"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="thick"
                                                    :items="thicks"
                                                    item-value="tebal"
                                                    item-text="tebal"
                                                    label="Thickness"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="width"
                                                    :items="widths"
                                                    item-value="lebar"
                                                    item-text="lebar"
                                                    label="Width"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="color"
                                                    :items="colors"
                                                    item-value="warna"
                                                    item-text="namawarna"
                                                    label="Color ID"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-5">
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-text-field
                                                    v-model="length"
                                                    dense
                                                    solo
                                                    label="Length"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="quality"
                                                    :items="qualities"
                                                    item-value="quality_id"
                                                    item-text="quality_id"
                                                    label="Quality"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="3">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="product_code"
                                                    :items="product_codes"
                                                    item-value="prod_code"
                                                    item-text="prod_code"
                                                    label="Product Code"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="add_id"
                                                    :items="add_ids"
                                                    item-value="add_id"
                                                    item-text="add_id"
                                                    label="ADD ID"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="5" md="2">
                                                <v-autocomplete
                                                    clearable
                                                    dense
                                                    solo
                                                    v-model="tor_id"
                                                    :items="tor_ids"
                                                    item-value="tor_id"
                                                    item-text="tor_id"
                                                    label="Tora ID"
                                                    class="border-12"
                                                    hide-details
                                                    :disabled="disabled_search == true"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-5">
                                            <v-col cols="6" class="text-left">
                                                <v-btn
                                                    class="mx-2"
                                                    fab
                                                    dark
                                                    color="indigo"
                                                    @click="plusTf()"
                                                >
                                                    <v-icon dark>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6" class="text-right">
                                                <v-btn
                                                    class="mr-2"
                                                    color="error"
                                                    elevation="2"
                                                    large
                                                    rounded
                                                    @click="submit()"
                                                >
                                                    <v-icon dark>
                                                        mdi-magnify
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                                            <v-layout align-center pr-4>
                                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                            <v-layout column>
                                                <div>
                                                <strong>{{ snackbar.title }}</strong>
                                                </div>
                                                <div>{{ snackbar.text }}</div>
                                            </v-layout>
                                            </v-layout>
                                            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                                            <v-icon>clear</v-icon>
                                            </v-btn>
                                        </v-snackbar>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="12" style="padding-bottom: 100px">
                                <v-card v-show="display == 'none' ? false: true">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title>WH ORI
                                                    <v-spacer></v-spacer>
                                                    <v-text-field
                                                        v-model="$store.state.text"
                                                        append-icon="mdi-magnify"
                                                        label="Search Product"
                                                        single-line
                                                        @keyup.enter="search"
                                                        hide-details
                                                    ></v-text-field>
                                                </v-card-title>
                                                
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="wh_ohs"
                                                    :options.sync="options"
                                                    :loading="$store.state.loading"
                                                    loading-text="Please wait, retrieving data"
                                                    :server-items-length="totalItems"
                                                    page-count="10"
                                                    class="elevation-1"
                                                    dense
                                                    height="400"
                                                    fixed-header
                                                >
                                                    <template v-slot:[`item.qty_oh`]="{ item }">
                                                        {{ $store.getters.convertToCurrency(item.qty_oh) }}
                                                    </template>
                                                    <template v-slot:[`item.wgt_oh`]="{ item }">
                                                        {{ $store.getters.convertToCurrency(item.wgt_oh) }}
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small class="mr-2" @click="editItem(item)" v-on="on">
                                                                    mdi-pencil
                                                                </v-icon>
                                                            </template>
                                                            <span>Move to</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                                <v-dialog v-model="$store.state.dialog" max-width="900px">                
                                    <v-card>
                                        <v-card-title>Move to Warehouse Dest Temp ID {{ this.warehouse_dest }}</v-card-title>
                                        <v-card-text>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="dataWhOh.NamaBarang" label="Product Description" readonly></v-text-field>
                                                    </v-col>
                                                    <!-- <v-col cols="2">
                                                        <v-text-field v-model="dataWhOh.qty_oh" label="Max Qty" readonly></v-text-field>
                                                    </v-col> -->
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="10">
                                                        <v-text-field v-model="remark_dtl_whoh" label="Remark"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field v-model="qty_oh" label="Qty" type="number" min="0" :max="dataWhOh.qty_oh"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="dataWhOh.ord_flag === 'Y' && dataWhOh.order_id != ''">
                                                    <v-col cols="10">
                                                        <v-text-field v-model="order" label="Order ID"></v-text-field>
                                                        <!-- <v-autocomplete
                                                            v-model="order"
                                                            :items="orders"
                                                            item-value="order_id"
                                                            item-text="order_id"
                                                            label="Order ID"
                                                            @change="(event) => updateItemOrder(event)"
                                                        ></v-autocomplete> -->
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field v-model="item_num" label="Item"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                            <v-btn
                                                class="ma-2"
                                                :loading="loading4"
                                                :disabled="loading4"
                                                color="success"
                                                @click="saveMovetoDestTemp()"
                                                >
                                                Save
                                                <template v-slot:loader>
                                                    <span class="custom-loader">
                                                    <v-icon light>mdi-cached</v-icon>
                                                    </span>
                                                </template>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item
                    value="tab-2"
                >
                    <warehouse-transfer-temperory />
                </v-tab-item>
                <v-tab-item
                    value="tab-3"
                >
                    <warehouse-transfer-destination />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
        
      </v-row>
    </v-container>
</template>
<script>
  import WarehouseTransferTemperory from './WarehouseTransferTemperory.vue'
  import WarehouseTransferDestination from './WarehouseTransferDestination.vue'

  export default {
    components:{
        WarehouseTransferTemperory,
        WarehouseTransferDestination
    },
    data() {
        return {
            items: ['02'],
            values: ['foo', 'bar'],
            shifts: [1,2,3],
            shift: '',
            value: '02',
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Production',
                disabled: false,
                href: '/admin/kka/production',
                },
                {
                text: 'Finish Good Transaction',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Transfer Warehouse',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            datetime: new Date(),
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            tr_id: '',
            periode: new Date().toISOString().substr(0, 7).replace(/-/g,""),
            error: true,
            show: false,
            errors: [],
            loading: false,
            loading_wh_transes: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            mills: [],
            mill: '',
            warehouse: '',
            warehouses: [],
            warehouse_dest: '',
            warehouses_dest: [],
            timeout: 7500,
            disabled : 1,
            display: 'none',
            displayDest: 'none',
            headers: [
                { text: 'Actions', align: 'start',  value: 'actions', sortable: false },
                {
                    text: 'BIN ID',
                    sortable: true,
                    value: 'bin_id',
                },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Description', value: 'NamaBarang' },
                { text: 'Color', value: 'NamaWarna' },
                { text: 'Add ID', value: 'add_id' },
                { text: 'TOR ID', value: 'tor_id' },
                { text: 'ACP ID', value: 'acp_id' },
                { text: 'Length', value: 'panjang' },
                { text: 'Batch ID', value: 'batch_id' },
                { text: 'Qty', value: 'qty_oh' },        
                { text: 'Wgt', value: 'wgt_oh' },
                { text: 'Order ID', value: 'order_id' },
                { text: 'Order Item Num', value: 'item_num' },
            ],
            totalItems: 10,
            options: {},
            wh_ohs: [],
            header_wh_transes: [
                {
                    text: 'TR ID',
                    align: 'start',
                    sortable: true,
                    value: 'tr_id',
                },
                { text: 'TR Date', value: 'tr_date' },
                { text: 'Remark', value: 'remark' },
                { text: 'User ID', value: 'user_id' },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            wh_transes: [],
            dataWhOh: {},
            remark: '',
            remark_dtl_whoh: '',
            qty_oh: 0,
            orders: [],
            order: '',
            item_nums: [],
            item_num: '',
            loading4: false,
            loader: null,
            tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MILLPRODUCTION'
            },
            mill_id: '',
            dateProps: {
                headerColor: 'blue',
                allowedDates: this.allowedDates
            },
            loc_id: '',
            disabled_search: true,
            status_req: true,
            disabled_mill: true,
            disabled_wh: true,
            disabled_wh_dest: true,
            machines: [],
            machine: '',
            groups: [],
            group: '',
            thicks: [],
            thick: '',
            widths: [],
            width: '',
            colors: [],
            color: '',
            lengths: [],
            length: '',
            qualities: [],
            quality: '',
            product_codes: [],
            product_code: '',
            add_ids: [],
            add_id: '',
            tor_ids: [],
            tor_id: '',
            bin_whs: [],
            bin_wh: '',
            bin_wh_dests: [],
            bin_wh_dest: '',
            group_user: ''
        }
    },
    async mounted(){
        // this.getMill()
    },
    methods:{
        async plusTf(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/check_status`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.status_req = res.data.data.status

                if (this.status_req === true) {
                    this.mill = ""
                    this.warehouse = ""
                    this.warehouse_dest = ""
                    this.value = '02'
                    this.tr_id = ""
                    this.remark = ""
                    this.remark_dtl_whoh = ""
                    this.qty_oh = ""
                    this.order = ""
                    this.item_num = ""
                    this.disabled_search = false
                    this.wh_ohs = []
                    this.totalItems = 10
                    this.display = 'none'
                    this.shift = ''
                    this.machine = ''
                    this.group = ''
                    this.thick = ''
                    this.width = ''
                    this.color = ''
                    this.length = ''
                    this.quality = ''
                    this.product_code = ''
                    this.add_id = ''
                    this.tor_id = ''
                    this.warehouse = ''
                    this.warehouse_dest = ''

                    this.getMill()
                    this.getUserGroup()

                } else {
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info!",
                        text: "Please complete is existing transfer",
                        visible: true
                    };
                    return false;
                }
            })

        },
        async generateTRId(){
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var localISOTime = (new Date(this.datetime - tzoffset)).toISOString().slice(0, -1);

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/gen_tr_id?millID=${this.mill}&wh_id=${this.warehouse}&bin_id=${this.bin_wh}&period=${this.periode}&shift=${this.shift}&wh_dest=${this.warehouse_dest}&bin_dest=${this.bin_wh_dest}&tr_date=${localISOTime}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.tr_id = res.data.tr_id
            })
        },
        close() {
            this.$store.state.dialog = false
        },
        search(){
            this.submit()
        },
        async submit(page = 1, itemsPerPage = 10){  

            this.wh_ohs = []        
            
            this.loading = true

            this.$store.state.loading = true

            if (this.mill === '' && this.warehouse === '' && this.warehouse_dest == '' && this.bin_wh === '' && this.bin_wh_dest === '' && this.shift === '') {
                this.loading = false
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill in the data first",
                  visible: true
                };
                return false;
            } else if (this.mill === '' || this.warehouse === '' || this.warehouse_dest == '' || this.bin_wh === '' || this.bin_wh_dest === '' || this.shift === '') {
                this.loading = false
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill in the data first",
                  visible: true
                };
                return false;
            } else{

                if (this.disabled_search === false) {
                    this.generateTRId()
                }
                this.display = 'block'
                this.disabled_search = true

                await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_wh_bal?search=${this.$store.state.text}&mill_id=${this.mill}&wh_id=${this.warehouse}&bin_id=${this.bin_wh}&period=${this.periode}&mach_type=${this.machine ? this.machine : ''}&jenis=${this.group ? this.group : ''}&tebal=${this.thick ? this.thick : ''}&warna=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}&quality_id=${this.quality ? this.quality : ''}&panjang=${this.length ? this.length : ''}&prod_code=${this.product_code ? this.product_code : ''}&batch_id=${this.batch ? this.batch : ''}&add_id=${this.add_id ? this.add_id : ''}&tor_id=${this.tor_id ? this.tor_id : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.$store.state.loading = false
                    this.loading = false
                    this.wh_ohs = res.data.data
                    this.totalItems = res.data.total
                })
            }

            
        },
        async getUserGroup() {   
            await axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=KKA&appl_id=MILLPRODUCTION`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
              this.group_user = res.data.data

                if (this.group_user === 'DEVELOPMENT') {
                    this.disabled_mill = false
                    this.disabled_wh = false
                }

            });
        },
        async getMill() {   
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'MILLID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.mill = res.data.data[i]['var_value']
                            this.disabled_mill = true
                        } else {
                            this.disabled_mill = false
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'WHID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.warehouse = res.data.data[i]['var_value']
                            this.disabled_wh = true
                        } else{
                            this.disabled_wh = false
                        }
                        
                    }
                }

                this.getMills()

                if(this.mill){
                    this.updateWarehouse(this.mill)
                    if (this.warehouse) {
                        this.updateWarehouseDest()
                    }
                } 

            })
            
        },
        async getMills(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        async updateWarehouse(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${value}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.warehouses = res.data
                // this.generateTRId(value)
                this.getMachine(value)
                this.getQuality(value)
                this.getProdCode(value)
                this.getAddId(value)
                this.getTorId(value)

            });
        },
        async updateWarehouseDest(){
            this.warehouse_dest = ''
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loc_id = res.data[0] ? res.data[0].loc_id : ''

                this.updateWarehouseDestLocId(this.loc_id)

                this.getBin()
            });
        },
        async updateWarehouseDestLocId(loc_id){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill}&loc_id=${loc_id ? loc_id : ''}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                for (let i = 0; i < res.data.length; i++) {

                    if (res.data[i].wh_id != this.warehouse) {
                        this.warehouses_dest.push(res.data[i])
                        
                        if (this.warehouse === 'SG03' && this.group_user != 'DEVELOPMENT') {
                            this.warehouse_dest = 'SG04'
                            this.disabled_wh_dest = true
                        } else {
                            this.disabled_wh_dest = false
                        }

                        if (this.warehouse === 'SG04' && this.group_user != 'DEVELOPMENT') {
                            this.warehouse_dest = 'SG03'
                            this.disabled_wh_dest = true
                        } else {
                            this.disabled_wh_dest = false
                        }
                    }
                }

                this.updateBinDest()


            });
        },
        async getBin(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.bin_whs = res.data
            });
        },
        async updateBinDest(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse_dest ? this.warehouse_dest : ''}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.bin_wh_dests = res.data
            });
        },
        allowedDates: (val) => {

            const date = new Date();
            const cur = {
                month: date.getMonth() + 1,
                year: date.getFullYear(),
                day: date.getDate()
            };

            let day = cur.year + '-' + '0' + cur.month + '-' + cur.day;

            let arrayDates = [day];

            for (var i = 0; i < arrayDates.length; i++) {
                if (arrayDates[i] == val){
                    return val
                }
            }
            
        },
        editItem(item){
            this.dataWhOh = item
            this.$store.state.dialog = true
            
            if (item.order_id != "") {
                this.order = item.order_id
                this.item_num = item.item_num
            } else{
                this.order = ""
                this.item_num = ""
            }
        },
        async getCustOrderNo(BarangJadiId, item){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_no?BarangJadiId=${BarangJadiId}&mill_id=${this.mill}&wh_id=${this.warehouse}&bin_id=${this.value}&batch_id=${item.batch_id}&panjang=${item.panjang}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.orders = res.data
            });
        }, 
        async updateItemOrder(value){

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_item?CustomerOrderNo=${value}&mill_id=${this.mill}&wh_id=${this.warehouse}&bin_id=${this.value}&batch_id=${this.dataWhOh.batch_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.item_nums = res.data
            });
        },
        async getMachine(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/machine_brg_jadi?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.machines = res.data
            });
        },
        async updateGroupJenis(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/jenis_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groups = res.data
            });

            this.getTebal(value)
            this.getLebar(value)
            this.getColor(value)

        },
        async getTebal(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tebal_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thicks = res.data
            });
        },
        async getLebar(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/lebar_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getColor(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/color_brg_jadi?mach_type=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colors = res.data
            });
        },
        async getQuality(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/quality?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        async getProdCode(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/prod_code?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.product_codes = res.data
            });
        },
        async getAddId(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/add_id?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.add_ids = res.data
            });
        },
        async getTorId(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tor_id?mill_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.tor_ids = res.data
            });
        },
        async saveMovetoDestTemp(){
            this.loading4 = true
            if (this.mill === '' && this.warehouse === '' && this.warehouse_dest == '' && this.bin_wh === '' && this.bin_wh_dest === '' && this.qty_oh == 0 && this.shift == "") {
                setTimeout(() => (this.loading4 = false), 2000)
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill in the data first",
                  visible: true
                };
                return false;
            } else if (this.mill === '' || this.warehouse === '' || this.warehouse_dest == '' || this.bin_wh === '' || this.bin_wh_dest === '' || this.qty_oh == 0 || this.shift == "") {
                setTimeout(() => (this.loading4 = false), 2000)
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill in the data first",
                  visible: true
                };
                return false;
            } else {
                if (this.qty_oh > parseInt(this.dataWhOh.qty_oh) || this.qty_oh < 0 ) {

                    setTimeout(() => (this.loading4 = false), 2000)
                    this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Make sure the number of items you move is not more or less",
                    visible: true
                    };
                    return false;

                } else{

                    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                    var localISOTime = (new Date(this.datetime - tzoffset)).toISOString().slice(0, -1);

                    await axios.post(`${process.env.VUE_APP_URL}/api/kka/warehouse/warehouse_tf_temp`, {
                        'mill_id': this.mill,
                        'tr_id': this.tr_id,
                        'tr_date': localISOTime,
                        'wh_id': this.warehouse,
                        'bin_id': this.bin_wh,
                        'wh_dest': this.warehouse_dest,
                        'bin_dest': this.bin_wh_dest,
                        'remark': this.remark ? this.remark : " ",
                        'tr_id_ref' : this.order ? this.order : " ",
                        'tr_id_seq_ref' : this.item_num ? this.item_num : " ",
                        'prod_code' : this.dataWhOh.prod_code,
                        'add_id' : this.dataWhOh.add_id,
                        'tor_id' : this.dataWhOh.tor_id,
                        'acp_id' : this.dataWhOh.acp_id,
                        'panjang' : this.dataWhOh.panjang,
                        'quality_id' : this.dataWhOh.quality_id,
                        'ord_flag' : this.dataWhOh.ord_flag,
                        'batch_id' : this.dataWhOh.batch_id,
                        'qty' : this.qty_oh,
                        'remark_dtl_whoh' : this.remark_dtl_whoh ? this.remark_dtl_whoh : " ",
                        'period' : this.periode,
                        'BarangJadiId' : this.dataWhOh.BarangJadiId,
                        'shift' : this.shift
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.loading4 = false
                        this.$store.state.dialog = false
                        this.qty_oh = 0
                        this.remark = ""
                        this.remark_dtl_whoh = ""
                        this.order = ''
                        this.item_num = ''
                        this.submit()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Move Product",
                            visible: true
                        };
                    }).catch(err => {
                        this.loading4 = false
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                    })
                }
                
            }
        }
    },
    watch: {
      options: {
        handler () {
            this.$store.state.loading = true
            const { page, itemsPerPage } = this.options
            if (this.mill != '' && this.warehouse != '' && this.warehouse_dest != '' && this.value != null) {
                this.submit(page, itemsPerPage)
            }
        },
        deep: true
      }
    }
  }
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>