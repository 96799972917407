<template>
    <v-container fluid class="mb-12">
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading_dest"
                        :indeterminate="loading_dest"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    class="border-12"
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="MILL ID"
                                    @change="(event) => updateWarehouse(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    class="border-12"
                                    v-model="warehouse"
                                    :items="warehouses"
                                    item-value="wh_id"
                                    item-text="descr"
                                    label="ORI WH ID"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    class="border-12"
                                    v-model="bin_wh"
                                    :items="bin_whs"
                                    item-value="bin_id"
                                    item-text="descr"
                                    label="ORI BIN ID"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                            cols="12" xs="12" sm="5" md="3"
                            >
                                <v-text-field
                                v-model="periode"
                                label="Period"
                                readonly
                                dense
                                solo
                                class="border-12"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                class="mr-2"
                                color="error"
                                elevation="2"
                                large
                                rounded
                                @click="getWhDest()"
                                >Search</v-btn>
                                <v-btn
                                class="mr-2"
                                color="success"
                                elevation="2"
                                large
                                rounded
                                @click="getWhDest()"
                                >Refresh</v-btn>
                            </v-col>
                        </v-row>
                        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                            <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                            </v-layout>
                            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                            </v-btn>
                        </v-snackbar>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>WH Destination
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search WH"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_temps"
                                    :items="warehouse_temps"
                                    :options.sync="option_temps"
                                    :loading="loading_dest"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    page-count="10"
                                    class="elevation-1"
                                    dense
                                    height="400"
                                    fixed-header
                                >
                                    <template v-slot:[`item.action_temps`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" persistent max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Warehouse Destination</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>WH DEST DETAIL
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="$store.state.text"
                                                append-icon="mdi-magnify"
                                                label="Search product"
                                                single-line
                                                @keyup.enter="search_temp"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_temp_details"
                                            :items="warehouse_temp_details"
                                            :options.sync="option_temp_details"
                                            :loading="loading5"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_temps"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"
                                            
                                        >
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col> 
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            whouses: [],
            totalItems: 10,
            options: {},
            totalItem_temps: 10,
            totalItem_temp_details: 10,
            option_temp_details: {},
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            error: true,
            show: false,
            errors: [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            periode: new Date().toISOString().substr(0, 7).replace(/-/g,""),
            mills: [],
            mill: '',
            warehouse: '',
            warehouses: [],
            warehouse_dest: '',
            warehouses_dest: [],
            items: ['02'],
            value: null,
            tr_id: '',
            modal: false,
            header_temps: [
                { text: 'Actions', align: 'start', value: 'action_temps', sortable: false },
                {
                    text: 'Mill ID',
                    sortable: true,
                    value: 'mill_id',
                },
                { text: 'Tr ID', value: 'tr_id' },
                { text: 'Sent Date', value: 'tr_send_date' },
                { text: 'Receive Date', value: 'tr_date' },
                { text: 'Warehouse', value: 'descr' },
                { text: 'Bin', value: 'bin_id' },
                { text: 'Remark', value: 'remark' },
                { text: 'User', value: 'user_id' },
            ],
            header_temp_details: [
                {
                    text: 'BIN ID',
                    align: 'start',
                    sortable: true,
                    value: 'bin_id',
                },
                { text: 'Receive Date', value: 'dt_modified', sortable: true },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Description', value: 'NamaBarang' },
                { text: 'Color', value: 'NamaWarna' },
                { text: 'Add ID', value: 'add_id' },
                { text: 'TOR ID', value: 'tor_id' },
                { text: 'ACP ID', value: 'acp_id' },
                { text: 'Length', value: 'panjang' },
                { text: 'Batch ID', value: 'batch_id' },
                { text: 'Qty', value: 'qty' },        
                { text: 'Wgt', value: 'wgt' },
                { text: 'Order Flag', value: 'ord_flag' },
                { text: 'Order ID', value: 'order_id' },
                { text: 'Order Num', value: 'item_num' },
                { text: 'Remark', value: 'remark' }
            ],
            warehouse_temps: [],
            warehouse_temp_details: [],
            option_temps: {},
            loading_dest: false,
            loading4: false,
            data_temp_wh: {},
            dialog: false,
            loading5: false,
            dialogAgree: false,
            remark_dtl_wh_temp: '', 
            qty_temp: 0,
            data_temp_wh_detail: {},
            orders: [],
            order: '',
            item_nums: [],
            item_num: '',
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MILLPRODUCTION'
            },
            bin_whs: [],
            bin_wh: '',
            pagination_item: 1
        }),
        mounted(){
            this.getMill()
            // this.getWhDest()
        },
        methods:{
            getMill() {   
                let data = this.data_var;

                this.$store.dispatch("getVariabelUser", { data }).then(res => {

                    for (let i = 0; i < res.data.data.length; i++) {

                        if (res.data.data[i]['var_id'] === 'MILLID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.mill = res.data.data[i]['var_value']
                                this.disabled_mill = true
                            } else {
                                this.disabled_mill = false
                            }
                        }
                        
                        if (res.data.data[i]['var_id'] === 'WHID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.warehouse = res.data.data[i]['var_value']
                                this.disabled_wh = true
                            } else{
                                this.disabled_wh = false
                            }
                            
                        }
                    }

                    this.getMills()

                    this.getWhDest()

                    if(this.mill){
                        this.updateWarehouse(this.mill)
                    } 

                })
            },
            getMills(){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.mills = res.data.data
                });
            },
            updateWarehouse(value){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${value}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.warehouses = res.data
                });
            },
            getBin(){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&status=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.bin_whs = res.data
                });
            },
            allowedDates: (val) => {
                const date = new Date();
                const cur = {
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                };
                const next = {
                    month: cur.month === 1 ? 12 : cur.month + 1,
                    year: cur.month === 1 ? cur.year + 1 : cur.year,
                };
                const valMonth = parseInt(val.split('-')[1], 10);
                const valYear = parseInt(val.split('-')[0], 10);

                return (valYear === cur.year && valMonth === cur.month) ||
                    (valYear === next.year && valMonth === next.month);
            },
            async getWhDest(page = 1, itemsPerPage = 10){
                this.loading_dest = true
                await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_wh_dest?search=${this.$store.state.text}&mill_id=${this.mill ? this.mill : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&bin_dest=${this.value ? this.value : " "}&wh_dest=${this.warehouse_dest}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_dest = false
                    this.warehouse_temps = res.data.data
                    this.totalItems = res.data.total
                })
            },
            close() {
                this.dialog = false
            },
            showItem(item){
                this.data_temp_wh = item
                this.dialog = true
                this.warehouse_temp_details = []
                this.getWhDestDetail(1, 10, item)
            },
            async getWhDestDetail(page = 1, itemsPerPage = 10, item){
                this.loading5 = true
                this.pagination_item = 1
                await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_wh_dest/${item.tr_id}?search=${this.$store.state.text}&mill_id=${item.mill_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading5 = false
                    this.warehouse_temp_details = res.data.data
                    this.totalItem_temp_details = res.data.total
                    this.pagination_item = res.data.current_page
                })
            },
            getCustOrderNo(BarangJadiId, item){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_no?BarangJadiId=${BarangJadiId}&mill_id=${item.mill_id}&wh_id=${item.wh_id}&bin_id=${item.bin_id}&batch_id=${item.batch_id}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.orders = res.data
                });
            }, 
            updateItemOrder(value){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_item?CustomerOrderNo=${value}&mill_id=${this.data_temp_wh_detail.mill_id}&wh_id=${this.data_temp_wh_detail.wh_id}&bin_id=${this.data_temp_wh_detail.bin_id}&batch_id=${this.data_temp_wh_detail.batch_id}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.item_nums = res.data
                });
            },
            search(){
                this.getWhDest()
            },
            search_temp(){
                this.getWhDestDetail(1, 10, this.data_temp_wh)
            },
        },
        watch: {
            option_temps: {
                handler () {
                    this.loading_dest = true
                    const { page, itemsPerPage } = this.option_temps
                    if (this.mill) {
                        this.getWhDest(page, itemsPerPage)
                    } else{
                        this.getWhDest(page, itemsPerPage)
                    }
                },
                deep: true,
            },
            option_temp_details: {
                handler () {
                    this.loading5 = true
                    const { page, itemsPerPage } = this.option_temp_details
                    if (this.data_temp_wh) {
                        this.getWhDestDetail(page, itemsPerPage, this.data_temp_wh)
                    }
                },
                deep: true,
            },
        },
    }
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>