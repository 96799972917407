<template>
    <v-container fluid class="mb-12">
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading_temp"
                        :indeterminate="loading_temp"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="MILL ID"
                                    @change="(event) => updateWarehouse(event)"
                                    :disabled="disabled_mill == true"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="warehouse"
                                    :items="warehouses"
                                    item-value="wh_id"
                                    item-text="descr"
                                    label="ORI WH ID"
                                    @change="updateWarehouseDest"
                                    :disabled="disabled_wh == true"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="bin_wh"
                                    :items="bin_whs"
                                    item-value="bin_id"
                                    item-text="descr"
                                    label="ORI BIN ID"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="warehouse_dest"
                                    :items="warehouses_dest"
                                    item-value="wh_id"
                                    item-text="descr"
                                    label="DESTINATION WH ID"
                                    @change="updateBinDest"
                                    :disabled="disabled_wh_dest == true"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="bin_wh_dest"
                                    :items="bin_wh_dests"
                                    item-value="bin_id"
                                    item-text="descr"
                                    label="Dest BIN ID"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12" xs="12" sm="5" md="4"
                            >
                                <v-datetime-picker 
                                    v-model="datetime_temp" 
                                    label="Date Trans"
                                    :date-picker-props="datePropTemps"
                                    time-format="HH:mm:ss"
                                    class="border-12"
                                ></v-datetime-picker>
                            </v-col>
                            <v-col
                            cols="12" xs="12" sm="5" md="4"
                            >
                                <v-autocomplete
                                    clearable
                                    dense
                                    solo
                                    v-model="shift"
                                    :items="shifts"
                                    label="Shift"
                                    class="border-12"
                                ></v-autocomplete>
                            </v-col>
                            <!-- <v-col
                                cols="2"
                                sm="2"
                            >
                                <v-text-field
                                v-model="tr_id"
                                label="TR ID"
                                disabled
                                ></v-text-field>
                            </v-col> -->
                            <v-col
                                cols="12" xs="12" sm="5" md="4"
                            >
                                <v-text-field
                                v-model="periode"
                                label="Period"
                                readonly
                                dense
                                solo
                                class="border-12"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                class="mr-2"
                                color="error"
                                elevation="2"
                                large
                                rounded
                                @click="getWhTemp()"
                                >Search</v-btn>
                                <!-- <v-btn
                                class="mr-2"
                                color="success"
                                elevation="2"
                                large
                                rounded
                                @click="getWhTemp()"
                                >Refresh</v-btn> -->
                            </v-col>
                        </v-row>
                        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                            <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                            </v-layout>
                            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                            </v-btn>
                        </v-snackbar>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card v-show="display == 'none' ? false: true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>WH TEMPORARY
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search Warehouse"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_temps"
                                    :items="warehouse_temps"
                                    :options.sync="option_temps"
                                    :loading="loading_temp"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    page-count="10"
                                    class="elevation-1"
                                    dense
                                    height="400"
                                    fixed-header
                                >
                                    <template v-slot:[`item.action_temps`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Warehouse Temp</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>WH TEMPORARY DETAIL
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="$store.state.text"
                                                append-icon="mdi-magnify"
                                                label="Search product"
                                                single-line
                                                @keyup.enter="search_temp"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_temp_details"
                                            :items="warehouse_temp_details"
                                            :options.sync="option_temp_details"
                                            :loading="loading5"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_temps"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"    
                                        >
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt) }}
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }" v-if="warehouse === data_temp_wh.wh_dest">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-2" @click="acceptItem(item)" v-on="on" color="green darken-2">
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Accept</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="periode_now == periode">
                                                    <template v-slot:activator="{ on }" v-if="periode_now == periode">
                                                        <v-icon small @click="cancelItem(item)" v-on="on" color="red darken-2">
                                                            mdi-cancel
                                                        </v-icon>
                                                    </template>
                                                    <span>Reject</span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-dialog
                                v-model="dialogAgree"
                                persistent
                                max-width="900px"
                                >
                                <v-card>
                                    <v-card-title>Move to Warehouse Dest ID {{ data_temp_wh.descr_dest }}</v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field v-model="data_temp_wh_detail.NamaBarang" label="Product Description" readonly></v-text-field>
                                                </v-col>
                                                <!-- <v-col cols="2">
                                                    <v-text-field v-model="data_temp_wh_detail.qty" label="Max Qty" readonly></v-text-field>
                                                </v-col> -->
                                            </v-row>
                                            <v-row>
                                                <v-col cols="10">
                                                    <v-text-field v-model="remark_dtl_wh_temp" label="Remark"></v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-text-field v-model="qty_temp" label="Qty" type="number" min="0" :max="data_temp_wh_detail.qty" readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="data_temp_wh_detail.ord_flag === 'Y'">
                                                <v-col cols="10">
                                                    <v-text-field v-model="data_temp_wh_detail.order_id" label="Order ID"></v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-text-field v-model="data_temp_wh_detail.item_num" label="Item"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        <v-btn
                                            class="ma-2"
                                            :loading="loading5"
                                            :disabled="loading5"
                                            color="success"
                                            @click="saveMovetoDest()"
                                            >
                                            Save
                                            <template v-slot:loader>
                                                <span class="custom-loader">
                                                <v-icon light>mdi-cached</v-icon>
                                                </span>
                                            </template>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogCancel"
                persistent
                max-width="400"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you return this item?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogCancel = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading6"
                            :disabled="loading6"
                            color="green darken-1"
                            @click="cancelSubmit()"
                            >
                            Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
            </v-col>    
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                whouses: [],
                totalItems: 10,
                totalItem_temps: 10,
                totalItem_temp_details: 10,
                option_temp_details: {},
                loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                error: true,
                show: false,
                errors: [],
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                periode: new Date().toISOString().substr(0, 7).replace(/-/g,""),
                mills: [],
                mill: '',
                warehouse: '',
                warehouses: [],
                warehouse_dest: '',
                warehouses_dest: [],
                items: ['02'],
                value: '02',
                tr_id: '',
                modal: false,
                header_temps: [
                    { text: 'Actions', align: 'start', value: 'action_temps', sortable: false },
                    {
                        text: 'Mill ID',                        
                        value: 'mill_id',
                    },
                    { text: 'Tr ID', value: 'tr_id' },
                    { text: 'Sent Date', value: 'tr_date' },
                    { text: 'Shift', value: 'shift' },
                    { text: 'Warehouse ORI', value: 'descr' },
                    { text: 'Bin ORI', value: 'bin_id' },
                    { text: 'Warehouse Dest', value: 'descr_dest' },
                    { text: 'Bin Dest', value: 'bin_dest' },
                    { text: 'Remark', value: 'remark' },
                    { text: 'User', value: 'user_id' },
                ],
                header_temp_details: [
                    { text: 'Actions', align: 'start',  value: 'actions', sortable: false },
                    {
                        text: 'BIN ID',
                        sortable: true,
                        value: 'bin_id',
                    },
                    { text: 'Sent Date', value: 'dt_modified', sortable: true },
                    { text: 'Tr ID', value: 'tr_id' },
                    { text: 'Prod Code', value: 'prod_code' },
                    { text: 'Description', value: 'NamaBarang' },
                    { text: 'Color', value: 'NamaWarna' },
                    { text: 'Add ID', value: 'add_id' },
                    { text: 'TOR ID', value: 'tor_id' },
                    { text: 'ACP ID', value: 'acp_id' },
                    { text: 'Length', value: 'panjang' },
                    { text: 'Batch ID', value: 'batch_id' },
                    { text: 'Qty', value: 'qty' },        
                    { text: 'Wgt', value: 'wgt' },
                    { text: 'Order Flag', value: 'ord_flag' },
                    { text: 'Order ID', value: 'order_id' },
                    { text: 'Order Num', value: 'item_num' },
                    { text: 'Remark', value: 'remark' },
                ],
                warehouse_temps: [],
                warehouse_temp_details: [],
                option_temps: {},
                loading_temp: false,
                loading4: false,
                data_temp_wh: {},
                dialog: false,
                loading5: false,
                dialogAgree: false,
                remark_dtl_wh_temp: '', 
                qty_temp: 0,
                data_temp_wh_detail: {},
                data_temp_wh_cancel_detail: {},
                orders: [],
                order: '',
                item_nums: [],
                item_num: '',
                dialogCancel: false,
                loading6: false,
                data_var: {
                    entity_id : 'KKA',
                    appl_id : 'MILLPRODUCTION'
                },
                display: 'none',
                loc_id: '',
                shifts: [1,2,3],
                shift: '',
                datetime_temp: new Date(),
                datePropTemps: {
                    headerColor: 'blue',
                    allowedDates: this.allowedDateTemps
                },
                periode_now: new Date().toISOString().substr(0, 7).replace(/-/g,""),
                disabled_mill: false,
                disabled_wh: false,
                disabled_wh_dest: false,
                bin_whs: [],
                bin_wh: '',
                bin_wh_dests: [],
                bin_wh_dest: '',
                group_user: '',
                pagination_item: 1

            }
        },
        mounted(){
            this.getMill()
        },
        methods:{
            generateTRId(mill){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/gen_tr_id?millID=${mill}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.tr_id = res.data.tr_id
                })
            },
            getMill() {   
                let data = this.data_var;

                this.$store.dispatch("getVariabelUser", { data }).then(res => {

                    for (let i = 0; i < res.data.data.length; i++) {

                        if (res.data.data[i]['var_id'] === 'MILLID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.mill = res.data.data[i]['var_value']
                                this.disabled_mill = true
                            } else {
                                this.disabled_mill = false
                            }
                        }
                        
                        if (res.data.data[i]['var_id'] === 'WHID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.warehouse = res.data.data[i]['var_value']
                                this.disabled_wh = true
                            } else{
                                this.disabled_wh = false
                            }
                            
                        }
                    }

                    this.getMills()

                    if(this.mill){
                        this.updateWarehouse(this.mill)
                        if (this.warehouse) {
                            this.updateWarehouseDest()
                        }
                    } 

                })
            },
            getMills(){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.mills = res.data.data
                });
            },
            updateWarehouse(value){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${value}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.warehouses = res.data
                    // this.generateTRId(value)
                });
            },
            updateWarehouseDest(){
                this.warehouse_dest = ''
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loc_id = res.data[0] ? res.data[0].loc_id : ''

                    this.updateWarehouseDestLocId(this.loc_id)

                    this.getBin()

                });
            },
            getBin(){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&status=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.bin_whs = res.data
                });
            },
            updateBinDest(){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse_dest ? this.warehouse_dest : ''}&status=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.bin_wh_dests = res.data
                });
            },
            updateWarehouseDestLocId(loc_id){
                axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill}&loc_id=${loc_id ? loc_id : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let i = 0; i < res.data.length; i++) {

                        if (res.data[i].wh_id != this.warehouse) {
                            this.warehouses_dest.push(res.data[i])
                        }
                    }
                });
            },
            allowedDateTemps: (val) => {
                const date = new Date();
                const cur = {
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                    day: date.getDate()
                };

                let Month1 = cur.month < 10 ? '0' + cur.month : cur.month;
                let Daycur1 = cur.day < 10 ? '0' + cur.day : cur.day;

                let day = cur.year + '-' + Month1 + '-' + Daycur1;

                var day2, day3;

                var letDay2 = cur.day - 1;
                var letDay3 = cur.day - 2;

                if (letDay2 <= 0) {
                    var month = 0; // January
                    var d = new Date(cur.year, month + 1, 0);

                    day2 = (new Date(d - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                } else{
                    let curMonth2 = cur.month < 10 ? '0' + cur.month : cur.month;
                    let curDay2 = letDay2 < 10 ? '0' + letDay2 : letDay2;

                    day2 = cur.year + '-' + curMonth2 + '-' + curDay2;
                }


                if (letDay3 <= 0) {
                    var month = 0; // January
                    var d = new Date(cur.year, month + 1, 0);

                    day3 = (new Date(d - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                } else{
                    let curMonth3 = cur.month < 10 ? '0' + cur.month : cur.month;
                    let curDay3 = letDay3 < 10 ? '0' + letDay3 : letDay3;

                    day3 = cur.year + '-' + curMonth3 + '-' + curDay3;
                }

                let arrayDates = [day3, day2, day];

                console.log(arrayDates, letDay2, letDay3);

                for (var i = 0; i < arrayDates.length; i++) {
                    if (arrayDates[i] == val){
                        return val
                    }
                }
                
            },
            async getWhTemp(page = 1, itemsPerPage = 10){
                if (this.mill === '' || this.mill === null) {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: "Please fill MILL and shift in the data first",
                        visible: true
                    };
                    return false;
                } else {
                    this.loading_temp = true
                    await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_wh_temp?search=${this.$store.state.text}&mill_id=${this.mill ? this.mill : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&bin_dest=${this.bin_wh_dest ? this.bin_wh_dest : " "}&wh_dest=${this.warehouse_dest}&shift=${this.shift}&period=${this.periode ? this.periode : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    })
                    .then(res => {
                        this.display = 'block'
                        this.loading_temp = false
                        this.warehouse_temps = res.data.data
                        this.totalItems = res.data.total
                    })
                }
                
            },
            close() {
                this.dialog = false
            },
            showItem(item){
                this.data_temp_wh = item
                this.dialog = true
                this.warehouse_temp_details = []
                this.getWhTempDetail(1, 10, item)
            },
            async getWhTempDetail(page = 1, itemsPerPage = 10, item){
                this.loading5 = true
                this.pagination_item = 1
                await axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_wh_temp_show/${item.tr_id}?search=${this.$store.state.text}&mill_id=${item.mill_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading5 = false
                    this.warehouse_temp_details = res.data.data
                    this.totalItem_temp_details = res.data.total
                    this.pagination_item = res.data.current_page


                    if (this.warehouse_temp_details.length > 0) {
                        this.dialog = true
                    } else {
                        this.dialog = false
                        // this.generateTRId(this.mill)
                    }

                })
            },
            getCustOrderNo(BarangJadiId, item){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_no?BarangJadiId=${BarangJadiId}&mill_id=${item.mill_id}&wh_id=${item.wh_id}&bin_id=${item.bin_id}&batch_id=${item.batch_id}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.orders = res.data
                });
            }, 
            updateItemOrder(value){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/warehouse/get_cust_order_item?CustomerOrderNo=${value}&mill_id=${this.data_temp_wh_detail.mill_id}&wh_id=${this.data_temp_wh_detail.wh_id}&bin_id=${this.data_temp_wh_detail.bin_id}&batch_id=${this.data_temp_wh_detail.batch_id}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.item_nums = res.data
                });
            },
            search(){
                this.getWhTemp()
            },
            search_temp(){
                this.getWhTempDetail(1, 10, this.data_temp_wh)
            },
            acceptItem(item){
                this.dialogAgree = true
                this.data_temp_wh_detail = item
                this.qty_temp = item.qty
                // this.getCustOrderNo(item.BarangJadiId, item)
            },
            cancelItem(item){
                console.log(item);
                console.log(this.data_temp_wh);
                this.data_temp_wh_cancel_detail = item
                this.qty_temp = item.qty
                this.dialogCancel = true
            },
            async cancelSubmit(){
                if (this.shift == '') {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: "Please fill shift in the data first",
                        visible: true
                    };
                    return false;
                } else {
                    this.loading6 = true

                    await axios.delete(`${process.env.VUE_APP_URL}/api/kka/warehouse/cancel_wh_tf`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        },
                        data: {
                            'mill_id': this.data_temp_wh.mill_id,
                            'tr_id': this.data_temp_wh.tr_id,
                            'tr_seq': this.data_temp_wh_cancel_detail.tr_seq,
                            'tr_date': this.data_temp_wh.dt_trans,
                            'wh_id': this.data_temp_wh.wh_id,
                            'bin_id': this.data_temp_wh.bin_id,
                            'wh_dest': this.data_temp_wh.wh_dest,
                            'bin_dest': this.data_temp_wh.bin_dest,
                            'tr_id_ref' : this.data_temp_wh_cancel_detail.order_id ? this.data_temp_wh_cancel_detail.order_id : " ",
                            'tr_id_seq_ref' : this.data_temp_wh_cancel_detail.item_num ? this.data_temp_wh_cancel_detail.item_num : " ",
                            'prod_code' : this.data_temp_wh_cancel_detail.prod_code,
                            'add_id' : this.data_temp_wh_cancel_detail.add_id,
                            'tor_id' : this.data_temp_wh_cancel_detail.tor_id,
                            'acp_id' : this.data_temp_wh_cancel_detail.acp_id,
                            'panjang' : this.data_temp_wh_cancel_detail.panjang,
                            'quality_id' : this.data_temp_wh_cancel_detail.quality_id,
                            'ord_flag' : this.data_temp_wh_cancel_detail.ord_flag,
                            'batch_id' : this.data_temp_wh_cancel_detail.batch_id,
                            'qty' : this.qty_temp,
                            'period' : this.data_temp_wh_cancel_detail.period,
                            'BarangJadiId' : this.data_temp_wh_cancel_detail.BarangJadiId
                        }
                    }).then(res => {
                        this.loading6 = false
                        this.dialogCancel = false
                        // this.dialog = false
                        this.warehouse_temp_details = []
                        this.getWhTempDetail(1, 10, this.data_temp_wh_detail)
                        this.getWhTemp()

                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Cancel Product",
                            visible: true
                        };
                    }).catch(err => {
                        this.loading6 = false

                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                    })
                }
                
            },
            async saveMovetoDest(){

                this.loading5 = true

                if (this.qty_temp === 0 || this.shift == '') {
                    this.loading5 = false
                    this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill Shift in the data first",
                    visible: true
                    };
                    return false;
                } else if (this.qty_temp === 0 || this.shift == '') {
                    this.loading5 = false
                    this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill Shift in the data first",
                    visible: true
                    };
                    return false;
                } else{
                    if (this.qty_temp > parseInt(this.data_temp_wh_detail.qty) || this.qty_temp < 0) {
                        this.loading5 = false

                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: "Make sure the number of items you move is not more or less",
                            visible: true
                        };
                        return false;
                    } else {
                        await axios.post(`${process.env.VUE_APP_URL}/api/kka/warehouse/warehouse_tf_to_dest`, {
                            'mill_id': this.data_temp_wh.mill_id,
                            'tr_id': this.data_temp_wh.tr_id,
                            'tr_id_trans': this.data_temp_wh.tr_id,
                            'tr_seq': this.data_temp_wh_detail.tr_seq,
                            'tr_date': this.data_temp_wh.dt_trans,
                            'wh_id': this.data_temp_wh.wh_id,
                            'bin_id': this.data_temp_wh.bin_id,
                            'wh_dest': this.data_temp_wh.wh_dest,
                            'bin_dest': this.data_temp_wh.bin_dest,
                            'remark': this.data_temp_wh.remark,
                            'tr_id_ref' : this.data_temp_wh_detail.order_id ? this.data_temp_wh_detail.order_id : " ",
                            'tr_id_seq_ref' : this.data_temp_wh_detail.item_num ? this.data_temp_wh_detail.item_num : " ",
                            'prod_code' : this.data_temp_wh_detail.prod_code,
                            'add_id' : this.data_temp_wh_detail.add_id,
                            'tor_id' : this.data_temp_wh_detail.tor_id,
                            'acp_id' : this.data_temp_wh_detail.acp_id,
                            'panjang' : this.data_temp_wh_detail.panjang,
                            'quality_id' : this.data_temp_wh_detail.quality_id,
                            'ord_flag' : this.data_temp_wh_detail.ord_flag,
                            'batch_id' : this.data_temp_wh_detail.batch_id,
                            'qty' : this.qty_temp,
                            'remark_dtl_whoh' : this.remark_dtl_wh_temp ? this.remark_dtl_wh_temp : " ",
                            'period' : this.data_temp_wh_detail.period,
                            'BarangJadiId' : this.data_temp_wh_detail.BarangJadiId,
                            'shift' : this.shift
                        }, { 
                            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                        }).then(res => {
                            this.loading5 = false
                            this.dialogAgree = false
                            this.qty_temp = 0
                            this.remark_dtl_wh_temp = ""
                            // this.dialog = false
                            this.warehouse_temp_details = []
                            this.getWhTempDetail(1, 10, this.data_temp_wh_detail)
                            this.getWhTemp()

                            this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Move Product to Destination",
                                visible: true
                            };
                        }).catch(err => {
                            this.loading5 = false
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.data.message,
                                visible: true
                            };
                        })
                    }

                }
            }
        },
        watch: {
            option_temps: {
                handler () {
                    const { page, itemsPerPage } = this.option_temps
                    if (this.mill != '' || this.mill != null) {
                        this.getWhTemp(page, itemsPerPage)
                    }
                },
                deep: true,
            },
            option_temp_details: {
                handler () {
                    this.loading5 = true
                    const { page, itemsPerPage } = this.option_temp_details
                    if (this.data_temp_wh) {
                        this.getWhTempDetail(page, itemsPerPage, this.data_temp_wh)
                    }
                },
                deep: true,
            },
            datetime_temp(val) {
                this.periode = new Date(val).toISOString().substr(0, 7).replace(/-/g,"")
                console.log(this.periode);
            }
        },
    }
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>